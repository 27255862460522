// import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const Ads = () => {
  // const navigate = useNavigate();
  // const { type } = useSelector((state) => state.user);
  const isMobile = useMediaQuery({ maxWidth: 640 });

  // const shopCategory = (cat, id) => {
  //   navigate(`/${type.toLowerCase()}/subcategory/${cat}/cid=${id}&page=1`);
  // };

  return (
    <div className="ads-container">
      <img
        src={
          isMobile
            ? "https://res.cloudinary.com/losode-ng/image/upload/v1743001658/banner/black-banner-mobile_x9yvze.png"
            : "https://res.cloudinary.com/losode-ng/image/upload/v1743001658/banner/black-banner-web_z6y1cy.png"
        }
        style={{ width: "100%", objectFit: "contain" }}
        alt="ads"
      />

      <style jsx="true">
        {`
          .ads-container {
            display: flex;
            justify-content: center;
            align-items: center;
            // height: 262px;
            margin: 0px 0 60px;
            padding: 0 4rem;
          }
          .btn-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            margin: 0 56px;
          }
          .ads-text {
            font: normal 600 45px DomaineSansText-Light;
            line-height: 67px;
            margin: 0;
            color: #000;
          }
          .ads-desc {
            font: normal 400 2vw DomaineSansText-Regular;
            line-height: 41px;
            margin: 0;
            color: #000;
          }
          .ads-btn {
            font: normal 400 16px DomaineSansText-Light;
            margin-top: 40px;
            margin-bottom: 0;
            color: #555555;
            cursor: pointer;
            text-decoration: underline;
          }
          .ads-img {
            flex-basis: 50%;
            background: url("/images/adbanner.png");
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
          }
          .txt-ads-title {
            font-family: "DomaineSansText-Black";
            font-size: 2.5vw;
            margin: 0;
            line-height: normal;
          }

          @media (max-width: 640px) {
            .ads-container {
              margin: 24px 16px 40px;
              height: 10rem;
            }
            .btn-container {
              margin: 0;
              margin-left: 16px;
              height: 46px;
            }
            .txt-ads-title {
              font-size: 16px;
              margin-bottom: 4px;
              line-height: normal;
            }
            .ads-desc {
              font-size: 14px;
              margin: 0;
              line-height: normal;
            }
            .ads-btn {
              text-decoration: underline;
              margin: 16px 0;
              font-size: 12px;
            }
            .ads-img {
              margin-right: 16px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Ads;
